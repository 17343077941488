import React from "react";
import RiverviewAcresLogo from "../img/RiverviewAcresLogo.png";

function Header() {
  return (
    <div className="header">
      <div className="riverview-acres-logo">
        <img src={RiverviewAcresLogo} alt="Riverview Acres logo" />
      </div>
      <div className="header-text">
        <h1>Katahdin Sheep</h1>
        <h4>Decaturville, Tennessee</h4>
      </div>
    </div>
  );
}

export default Header;
