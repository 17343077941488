import React from "react";

function Alert() {
  return (
    <div className="alert">
      <h3>
        Please check back in a few months on information on upcoming lambs!
      </h3>
    </div>
  );
}

export default Alert;
