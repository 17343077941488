import React from "react";
import { Divider, Button } from "antd";

function NavBar() {
  return (
    <div className="nav-bar">
      <Divider />
      <div className="links">
        <Button type="link" className="nav-link">
          Home/News
        </Button>
        <Button type="link" className="nav-link">
          Rams&Ewes
        </Button>
        <Button type="link" className="nav-link">
          Lambs for Sale
        </Button>
        <Button type="link" className="nav-link">
          Contact Us
        </Button>
      </div>
      <Divider />
    </div>
  );
}
export default NavBar;
