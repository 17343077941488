import Header from "../componenets/Header";
import NavBar from "../componenets/NavBar";
import Alert from "../componenets/Alert";

import katieSkeeter from "../img/katieSkeeter.jpg";
import skeeterLookRight from "../img/skeeterLookRight.jpg";
import skeeterLookStraight from "../img/skeeterLookStraight.jpg";

function HomePage() {
  return [
    <div className="home-page">
      <Header />
      <NavBar />
      <Alert />
      <div className="section">
        <div className="article">
          <h3 className="article-header">
            Now introducing our employee of the month!
          </h3>
          <p className="article-content">
            This month’s selection is the one and only Skeeter, and we are
            grateful for his dedication and his service. He watches over the
            sheep day and night, no matter the weather conditions.
          </p>
        </div>
        <div className="img-gallary">
          <div className="top-img">
            <div className="katieSkeeter">
              <img src={katieSkeeter} alt="Katie and Skeeter" />
            </div>
            <div className="skeeterLookRight">
              <img src={skeeterLookRight} alt="Dog looking right" />
            </div>
          </div>
          <div className="bottom-img">
            <img
              src={skeeterLookStraight}
              className="skeeterLookStraight"
              alt="dog looking straight"
            />
          </div>
        </div>
      </div>
    </div>,
  ];
}

export default HomePage;
