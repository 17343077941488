import "./App.css";
import HomePage from "./pages/HomePage";
function App() {
  return (
    <div className="App-wrapper">
      <div className="App">
        <HomePage />
      </div>
    </div>
  );
}

export default App;
